import React from "react";

import { SpanTagStyle } from "./TextStyles.style";

interface SpanTagProps {
  fontSize?: any;
  fontWeight?: any;
  color?: any;
  textAlign?: any;
  children: any;
  margin?: any;
  flexShrink?: number;
}

const SpanTag: React.FC<SpanTagProps> = ({
  fontSize = "sm",
  fontWeight = "regular",
  color = "black",
  textAlign,
  margin,
  children,
  ...rest
}) => {
  return (
    <SpanTagStyle
      margin={margin}
      textAlign={textAlign}
      fontSize={fontSize}
      fontWeight={fontWeight}
      color={color}
      {...rest}
    >
      {children}
    </SpanTagStyle>
  );
};

export default SpanTag;
