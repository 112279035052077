import styled from "styled-components";

const renderAlign = (alignItems: string) => {
  switch (alignItems) {
    case "center":
      return `
            align-items:center;
          `;
    case "baseline":
      return `
        align-items:baseline;
          `;
    case "flex-start":
      return `
        align-items:flex-start;
          `;
    case "flex-end":
      return `
        align-items:flex-end;
          `;
    default:
      return `
        align-items:center;
      `;
  }
};

const renderJustify = (justifyContent: string) => {
  switch (justifyContent) {
    case "center":
      return `
            justify-content:center;
          `;
    case "space-between":
      return `
        justify-content:space-between;
          `;
    default:
      return `
        justify-content:center;
      `;
  }
};

const renderDir = (flexDirection: string) => {
  switch (flexDirection) {
    case "row":
      return `
            flex-direction:row;
          `;
    case "column":
      return `
        flex-direction:column;
          `;
    default:
      return `
        flex-direction:row;
      `;
  }
};

export const FlexCompStyle = styled.div<any>`
  display: flex;
  position: relative;
  ${(p) =>
    p.alignItems &&
    `
    ${renderAlign(p.alignItems)}
  `}
  ${(p) =>
    p.justifyContent &&
    `
    ${renderJustify(p.justifyContent)}
  `}
  ${(p) =>
    p.flexDirection &&
    `
    ${renderDir(p.flexDirection)}
  `}
gap: var(--gap,0);
  flex: var(--flex);
  flex-shrink: var(--flexShrink, unset);
  margin: var(--flexMargin, unset);
  padding: var(--padding, 0);
  border-bottom: var(--borderBottom, none);
  border-top: var(--borderTop, none);
  background: var(--bgColor, transparent);
  border-radius: var(--borderRadius, 0);
  height: var(--tm-height, unset);
  width: var(--tm-width, unset);
`;
