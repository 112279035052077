import styled from "styled-components";

const renderFont = (fontSize: string) => {
  switch (fontSize) {
    case "xxs":
      return `
          font-size: 10px;
          line-height: 16px;
        `;
    case "xs":
      return `
          font-size: 12px;
          line-height: 16px;
        `;
    case "sm":
      return `
          font-size: 14px;
          line-height: 20px;
        `;
    case "md":
      return `
        font-size: 16px;
        line-height: 24px;
      `;
    case "large":
      return `
          font-size: 18px;
          line-height: 26px;
        `;
    case "xlarge":
      return `
        font-size: 22px;
        line-height: 32px;
      `;
    case "xxlarge":
      return `
        font-size: 24px;
        line-height: 32px;
      `;
    case "xxxlarge":
      return `
        font-size: 28px;
        line-height: 32px;
      `;
    default:
      return `
      font-size: 14px;
      line-height: 20px;
    `;
  }
};

const renderFontWeight = (fontWeight: string) => {
  switch (fontWeight) {
    case "regular":
      return `
          font-weight: 400;
        `;
    case "medium":
      return `
        font-weight: 500;
      `;
    case "semiBold":
      return `
        font-weight: 600;
      `;
    case "bold":
      return `
        font-weight: 700;
      `;
    case "black":
      return `
      font-weight: 900;
        `;
    default:
      return `
      font-weight: 400;
    `;
  }
};

export const H1TagStyle = styled.h1`
  font-size: 56px;
  line-height: 72px;
  font-weight: 500;
  margin: var(--margin, 0);
  font-family: "Raleway";
  color: var(--color, #4a4a4a);

  span {
    font-size: 56px;
    line-height: 72px;
    font-weight: 500;
    margin: var(--margin, 0);
    font-family: "Raleway";
    color: var(--color, #4a4a4a);
  }
`;

export const H2TagStyle = styled.h2`
  font-size: 48px;
  line-height: 64px;
  font-weight: 500;
  margin: var(--margin, 0);
  font-family: "Raleway";
  color: var(--color, #4a4a4a);
`;

export const H3TagStyle = styled.h3`
  font-size: 40px;
  line-height: 54px;
  font-weight: 500;
  margin: var(--margin, 0);
  font-family: "Raleway";
  color: var(--color, #4a4a4a);
  text-align: var(--textAlign, left);
`;

export const H4TagStyle = styled.h4`
  font-size: 32px;
  line-height: 40px;
  font-weight: 500;
  margin: var(--margin, 0);
  font-family: "Raleway";
  color: var(--color, #4a4a4a);
`;

export const H5TagStyle = styled.h5`
  font-size: 24px;
  line-height: 32px;
  margin: var(--margin, 0);
  font-family: "Raleway";
  color: var(--color, #4a4a4a);
  font-weight: var(--fontWeight, 500);
  span {
    font-size: 24px;
    line-height: 32px;
    font-family: "Raleway";
    font-weight: var(--fontWeight, 500);
  }
`;

export const H6TagStyle = styled.h6<any>`
  font-size: 16px;
  line-height: 25px;
  font-weight: var(--fontWeight, 500);
  margin: var(--margin, 0);
  font-family: "Raleway";
  color: var(--color, #4a4a4a);
`;

export const ParaTagStyle = styled.p<any>`
  font-family: "Open Sans";
  ${(p) =>
    p.fontSize
      ? `
    ${renderFont(p.fontSize)}
  `
      : ``}
  ${(p) =>
    p.fontWeight &&
    `
    ${renderFontWeight(p.fontWeight)}
  `}
  margin: ${(p) => p.margin && p.margin};
  text-align: ${(p) => p.textAlign && p.textAlign};
  color: ${(p) => p.color && p.color};
`;

export const SpanTagStyle = styled.span<any>`
  font-family: "Open Sans";
  ${(p) =>
    p.fontSize &&
    `
    ${renderFont(p.fontSize)}
  `}
  ${(p) =>
    p.fontWeight &&
    `
    ${renderFontWeight(p.fontWeight)}
  `}
  color: ${(p) => p.color && p.color};
  margin: ${(p) => p.margin && p.margin};
  text-align: ${(p) => p.textAlign && p.textAlign};
  display: inline-block;
`;

export const AnchorTagStyle = styled.a<any>`
  font-family: "Open Sans";
  ${(p) =>
    p.fontSize &&
    `
    ${renderFont(p.fontSize)}
  `}
  ${(p) =>
    p.fontWeight &&
    `
    ${renderFontWeight(p.fontWeight)}
  `}
  margin: ${(p) => p.margin && p.margin};
  display: inline-block;
  color: ${(p) => p.color && p.color};
  text-align: ${(p) => p.textAlign && p.textAlign};
`;

export const CliptextStyleComp = styled.div`
  background: linear-gradient(
    92.2deg,
    #ffe2e8 0.47%,
    #fffcfc 25.07%,
    #8cc8ea 64.68%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  width: fit-content;
  font-weight: 600;
`;
