import { useEffect, useState } from "react";
import { pointType } from "./points";
import { API } from "@tm/utility/constants";

export const useMapData = (limit = 500) => {
  const [mapPoints, setMapPoints] = useState<any>([]);

  useEffect(() => {
    fetch(`${API.GET_MAP_DATA}`)
      .then((res) => res.json())
      .then((data) => {
        const events = data?.data;
        if (events) {
          for (let i = 0; i < events.length; i++) {
            const event = events[i];
            event["event_name"] = pointType[event.eventName];
          }
          setMapPoints(events);
        }
      });
  }, []);

  return [mapPoints];
};
